/* General */

.container {
  border: 1px solid;
  border-color: var(--chakra-colors-gray-300);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}

.tab-btn {
  color: var(--chakra-colors-blue-500);
  padding: 10px 3px 8px;
  font-weight: 500;
  cursor: pointer;
}

.tab-btn.active {
  border-bottom: 3px solid var(--chakra-colors-blue-500);
}

.breadcrumb {
  margin: 25px 0;
  font-size: 32px;
}

.breadcrumb a:hover {
  color: var(--chakra-colors-blue-400);
  text-decoration: none;
  transition: 200ms;
}

/* Nav Bar */

ul.navbar {
  list-style: none;
}

ul.navbar li {
  display: inline;
  padding: 0 12px;
  font-weight: 500;
  margin-inline-start: 0;
  cursor: pointer;
}

/* Order Card */

.info-stack {
  min-height: inherit;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-inline-start: 0 !important;
}

.info-stack h5 {
  color: var(--chakra-colors-gray-500);
  font-weight: 800;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 7px;
}

.info-stack p {
  padding: 3px 0;
  white-space: normal;
}

.info-stack b {
  font-weight: 600;
}

/* Other */

.page {
  text-align: center;
}

button {
  background-color: #6200ee;
  color: #ffffff;
  border: none;
  padding: 12px 16px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

button:hover {
  background-color: #3700b3;
}

.full-width-input {
  display: block;
  text-align: right;
}

.full-width-input input {
  width: 12em;
  padding: 4px 8px;
  margin: 4px 8px;
  border: 1px solid #aaaaaa;
  border-radius: 0;
}
